function getWeekByDate(originalDate: string | null = null) {
  const date = originalDate ? new Date(originalDate) : new Date()
  const day = date.getDay()

  const firstDay = new Date()
  firstDay.setTime(date.getTime() - (day * 24 * 60 * 60 * 1000))

  const lastDay = new Date()
  lastDay.setTime(date.getTime() + ((6 - day) * 24 * 60 * 60 * 1000))

  return [firstDay.toISOString().split('T')[0], lastDay.toISOString().split('T')[0]]
}

const usersKPI = {
  prepayments: 7,
  presentations: 20,
  objectsCount: 6,
  clientsCount: 20
}

const clientsStatusesArray = [
  'Новый клиент',
  'Обработанный клиент',
  'Заявка на показ',
  'Показ совершён',
  'Одобрение ипотеки',
  'Аванс',
  'Выезд',
  'Провальные (отказ)'
]

const clientsStatusesSelect = [
  { label: 'Новый клиент', value: 0 },
  { label: 'Обработанный клиент', value: 1 },
  { label: 'Заявка на показ', value: 2 },
  { label: 'Показ совершён', value: 3 },
  { label: 'Одобрение ипотеки', value: 4 },
  { label: 'Аванс', value: 5 },
  { label: 'Выезд', value: 6 },
  { label: 'Провальные (отказ)', value: 7 }
]

const clientsTypes = [
  { label: 'Не указано', value: 0 },
  { label: 'Риелтор', value: 1 },
  { label: 'Юрист', value: 2 },
  { label: 'Основной клиент', value: 3 },
  { label: 'Представитель', value: 4 },
  { label: 'ВИП', value: 5 }
]

const clientsUnique = [
  { label: 'Не указано', value: 0 },
  { label: 'Новое обращение', value: 1 },
  { label: 'Повторное обращение', value: 2 }
]

const clientsSource = [
  { label: 'Не указано', value: 0 },
  { label: 'Сайт', value: 1 },
  { label: 'Рекламная площадка', value: 2 },
  { label: 'По совету знакомых', value: 3 },
  { label: 'Социальные сети', value: 4 },
  { label: 'СМИ', value: 5 }
]

export { getWeekByDate, usersKPI, clientsStatusesArray, clientsStatusesSelect, clientsTypes, clientsUnique, clientsSource }
