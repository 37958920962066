































































































































































































































































































































import { Vue, Component, Watch } from 'vue-class-decorator'
import { UserModule, GlobalModule } from '@/store'
import { Payload } from 'vue/interfaces'
import { usersKPI } from '@/utilities'

@Component({
  metaInfo: {
    title: 'Пользователи'
  }
})
export default class Users extends Vue {
  public userDialog: boolean
  public settingDialogTitle: string
  public tableUsers: Array<object>
  public selectedUser: { [propName: string]: any } | undefined
  public userDialogTitle: string
  public dialogKey: boolean
  public userImage: { [propName: string]: any } | null
  public userImageSrc: string
  public dialogRating: boolean
  public dialogRatingMonth: any
  public dialogRatingDatePickerMenu: boolean
  public dialogRatingDataTable: any
  public dialogRatingUserId: number
  $refs!: {
    dialogRatingDatePickerMenu: HTMLFormElement;
  }

  // Data
  constructor() {
    super()
    this.userDialog = false
    this.dialogKey = false
    this.settingDialogTitle = 'Создание'
    this.userDialogTitle = 'Создание'
    this.dialogRating = false
    this.dialogRatingDataTable = null
    this.dialogRatingMonth = null
    this.dialogRatingDatePickerMenu = false
    this.selectedUser = {}
    this.userImage = null
    this.userImageSrc = '/front/assets/no-image.png'
    this.dialogRatingUserId = 0
    this.tableUsers = [
      {
        text: 'Имя',
        value: 'title',
        align: 'start',
        sortable: false
      },
      {
        text: 'Группа',
        value: 'group',
        sortable: false
      },
      {
        text: 'Авторизация',
        value: 'last_date_format',
        sortable: false
      },
      {
        text: 'Опции',
        value: 'user_id',
        sortable: false
      }
    ]
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean

  @GlobalModule.Action('loadImage') loadImage!: (image: Payload) => Promise<any>

  @UserModule.Action('requestUserDelete') deleteUser!: (id: number) => Promise<void>
  @UserModule.Action('getRatingUser') getRatingUser!: (payload: Payload) => Promise<void>
  @UserModule.Action('saveUser') pushUser!: (user: Payload) => Promise<void>

  @UserModule.Action('requestUsersPageData') loadUsersPageData!: () => Promise<void>
  @UserModule.Getter('getUsers') users!: Array<object>
  @UserModule.Getter('userInfo') userInfo!: { [propName: string]: any }
  @UserModule.Getter('getUserGroups') userGroups!: Array<object>

  // Watch
  @Watch('userDialog') clearModalFields() {
    if (!this.userDialog) {
      this.clearFields()
      setTimeout(() => {
        this.dialogKey = !this.dialogKey
      }, 200)
    }
  }

  @Watch('userImage') async uploudImage() {
    if (this.userImage !== null && this.userImage?.size < 2000000) {
      this.loadImage(this.userImage)
        .then((res: any) => {
          this.selectedUser!.newImage = res.files[0].file_id
          this.userImageSrc = res.files[0].url
        })
        .catch((error: any) => {
          this.$noty('error', error.message, 7000).show()
        })
    }

    if (this.userImage === null) {
      this.userImageSrc = '/front/assets/no-image.png'
      this.selectedUser!.newImage = '0'
    }
  }

  get accessEdit() {
    if (this.userInfo.group !== undefined) {
      // return this.userInfo.group.group_id === 1 || this.selectedUser!.user_id === this.userInfo.user_id
      return this.userInfo.group.group_id === 1 || this.userInfo.group.group_id === 6
    } else {
      return false
    }
  }

  // Methods
  loadPageData() {
    this.loadUsersPageData()
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  saveMonth(rating) {
    this.$refs.dialogRatingDatePickerMenu.save(rating)
    this.viewRatingUser(this.dialogRatingUserId)
  }

  generateTableData(data) {
    // data.statistic = data.statistic.map((statusCount) => {
    //   return statusCount >= 1 ? Math.round(100 / data.count * statusCount) : 0
    // })

    // this.selectStatuses = [
    //   { label: 'Не указано', value: 0 },
    //   { label: 'Аванс', value: 1 },
    //   { label: 'Показ', value: 2 },
    //   { label: 'Отказ', value: 3 }
    // ]

    // usersKPI
    data.statistic = [
      // data.statistic[0],
      data.statistic[0] >= 1 ? Math.round(100 / usersKPI.prepayments * (data.statistic[5] - data.statistic[7])) : 0,
      data.statistic[1] >= 1 ? Math.round(100 / usersKPI.presentations * data.statistic[3]) : 0,
      data.statistic[2]
    ]

    if (data.user_group_id === 3) {
      const objectStatistic = data.objects_count >= 1 ? Math.round(100 / usersKPI.objectsCount * data.objects_count) : 0
      const clientsStatistic = data.count >= 1 ? Math.round(100 / usersKPI.clientsCount * data.count) : 0
      data.statistic[3] = (data.statistic[0] + data.statistic[1] + objectStatistic + clientsStatistic) / 4
    } else {
      data.statistic[3] = (data.statistic[0] + data.statistic[1]) / 2
    }

    // console.log(data)
    this.dialogRatingDataTable = data
  }

  deleteUserProcess(id: number) {
    this.deleteUser(id)
      .then(() => {
        this.$noty('success', 'Пользователь удален!', 5000).show()
        this.loadPageData()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  viewUser(id: number) {
    const user = this.users.find((user: any) => user.user_id === id)
    this.selectedUser = user
    if (this.selectedUser?.image?.file_id !== undefined && this.selectedUser?.image?.file_id !== 0) {
      this.userImageSrc = this.selectedUser?.image.web_url
      this.userImage = {
        name: this.selectedUser?.image.filename
      } as File
    }
    this.userDialogTitle = 'Редактирование'
    this.userDialog = true
  }

  viewRatingUser(id: number) {
    this.dialogRatingUserId = id
    const payload = {
      id,
      month: this.dialogRatingMonth
    }

    this.getRatingUser(payload)
      .then((res: any) => {
        this.generateTableData(res)
        this.dialogRating = true
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  clearFields() {
    this.userDialogTitle = 'Создание'
    this.selectedUser = {}
    this.userImage = null
  }

  saveUser() {
    this.pushUser(this.selectedUser as Payload)
      .then(() => {
        this.$noty('success', 'Пользователь сохранен!', 5000).show()
        this.userDialog = false
        this.loadPageData()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  getThisMonth() {
    return new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2)
  }

  // Hooks
  created() {
    this.loadPageData()
    this.dialogRatingMonth = this.getThisMonth()
  }
}
